<!--
 * @Author: John
 * @Date: 2022-02-05 12:59:43
 * @LastEditTime: 2022-05-07 19:07:06
 * @LastEditors: John
 * @Description: 重置密码
 * @FilePath: \jjms_ui\src\views\resetPwd.vue
 * @Copyright John
-->
<template>
    <div id="wrap">
        <div class="logo-box">
            <img src="@/assets/img/title_logo2.png" alt="">
        </div>
        <el-card class="box-card login-panel">
            <div slot="header" class="clearfix">
                <h2 style="text-align: center">重置密码</h2>
            </div>
            <el-form ref="resetForm" :model="formData" :rules="formRules" status-icon label-width="100px">
                <el-form-item label="邮箱：" prop="email">
                    <el-input v-model="formData.email" prefix-icon="el-icon-message" readonly></el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="password">
                    <el-input v-model="formData.password" prefix-icon="el-icon-lock" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="重复密码：" prop="rePassword">
                    <el-input v-model="formData.rePassword" prefix-icon="el-icon-lock" clearable show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit('resetForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="footer-bar">
            <footer-bar />
        </div>
    </div>
</template>
<script>
import api from '../api/req_api'
import sha256 from 'crypto-js/sha256'
import footerBar from '../components/footer-bar.vue'
export default {
    components: {
        footerBar
    },
    props: {
        email: {
            type: String,
            default: ''
        },
        token: {
            type: String,
            default: ''
        }
    },
    data(){
        let validateRePassword = (rule, value, callback) => {
            if(value !== this.formData.password) {
                callback(new Error('两次输入密码不一致!'));
                return;
            }
            callback();
        };
        return {
            formData: {
                password: '',
                rePassword: '',
                email: '',
                token: ''
            },
            formRules: {
                password: [
                    {required: true, message: '密码是必填项!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'}
                ],
                rePassword: [
                    {required: true, message: '请输入重复密码!', trigger: 'blur'},
                    { min: 6, max: 10, message: '长度在 6 到 10 个字符之间', trigger: 'blur'},
                    {validator: validateRePassword, trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        onSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formData.password = sha256(this.formData.password).toString();    // 对密码加密
                    this.formData.rePassword = '111111111111';
                    api.resetPwd(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.$message({message: "重置密码成功，请使用新密码登录!", type: 'success'});
                                this.$router.push('/login');
                            } 
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    created() {
        console.log(`email: ${this.email}, token: ${this.token}`);
        this.formData.email = this.email;
        this.formData.token = this.token;
    }
}
</script>